<template>
  <div id="tradeDetail">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_receive_120')}`" />
    <div class="common-card-body">
      <div class="back-tab flex-align" @click="$router.back(-1)">
        <left-outlined class="ic-back" />
        <span>{{ $t('pages_receive_121') }}</span>
      </div>
      <div class="account-top flex-align">
        <div class="flex-align top-area" @click="() => visible = true">
          <img :src="getCountryLogo(vaInfo.oneCurrency)">
          <span class="name">{{ vaInfo.simpleName }}</span>
          <form-outlined class="ic-edit" />
        </div>
        <a-dropdown v-if="themeData.openReceipt">
          <a-button
            shape="round"
            class="account-export"
            type="primary"
            ghost
            :loading="exportLoading"
          >
            <template #icon><download-outlined /></template>
            {{ exportLoading ? $t('common_text_023') : $t('pages_receive_154') }}
          </a-button>
          <template #overlay>
            <a-menu @click="menuChange">
              <a-menu-item key="normal">{{ $t('pages_receive_130') }}</a-menu-item>
              <a-menu-item v-if="vaInfo.businessChildType.includes('AMAZON')" key="amazon">{{ $t('pages_receive_131') }}</a-menu-item>
              <a-menu-item v-if="vaInfo.businessChildType.includes('EBAY')" key="ebay">{{ $t('pages_receive_132') }}</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="account-body">
        <div class="body-left">
          <div class="title">{{ $t('pages_receive_120') }}</div>
          <div class="content">
            <div class="content-left">
              <div
                v-for="info in infoArr"
                :key="info.key"
                class="name"
              >{{ info.name }}:</div>
            </div>
            <div class="content-right">
              <div
                v-for="info in infoArr"
                :key="info.key"
                class="name flex-align"
              >
                <a-tooltip>
                  <template #title>{{ info.value }}</template>
                  <span class="area">{{ info.value }}</span>
                </a-tooltip>
                <copy-outlined
                  v-if="info.copy && info.value"
                  class="ic-copy"
                  @click="copyToClipboard(info.value)"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="capacityArr.length !== 0" class="body-right">
          <div class="title">{{ $t('pages_receive_133') }}</div>
          <div class="content">
            <div class="content-left">
              <div
                v-for="index in capacityArr.length"
                :key="index"
                class="name"
              >{{ (index === 0 || index % 2 === 0) ? $t('pages_receive_134') : $t('pages_receive_135') }}</div>
            </div>
            <div class="content-right">
              <div
                v-for="info in capacityArr"
                :key="info.value"
                class="name flex-align"
              >
                <a-tooltip>
                  <template #title>{{ info.value }}</template>
                  <span class="area">{{ info.value }}</span>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="allowAuth" class="account-footer">
        <div class="title">店铺信息</div>
        <a-table
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="shopList"
        />
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      :title="$t('pages_receive_139')"
      :mask-closable="false"
      @ok="nameUpdate"
    >
      <a-form ref="formRef" :model="nameForm" layout="vertical">
        <a-form-item
          name="simpleName"
          :label="$t('pages_receive_005')"
          :rules="[{ required: true, message: $t('common_text_006') + $t('pages_receive_005') }]"
        >
          <a-input
            v-model:value="nameForm.simpleName"
            :placeholder="$t('common_text_006') + '...'"
            allow-clear
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <open-cert
      v-model:visible="openVisible"
      :open-text="openText"
      :va-info="vaInfo"
    />
  </div>
</template>
<script>
import { ref, reactive, toRefs, inject } from 'vue'
import HeaderNav from '@/components/header-nav'
import OpenCert from './components/open-cert'
import { useRoute } from 'vue-router'
import { _receive } from '@/api'
import i18n from '@/locale'
import moment from 'moment'
import { message } from 'ant-design-vue'
import { getCountryLogo, copyToClipboard, getTradeStatusName } from '@/util'
export default {
  name: 'TradeDetail',
  components: {
    'header-nav': HeaderNav,
    'open-cert': OpenCert
  },
  setup () {
    const route = useRoute()
    const formRef = ref(null)

    const state = reactive({
      themeData: inject('$themeData'),
      loading: false,
      visible: false,
      openVisible: false,
      allowAuth: false,
      openText: '',
      exportLoading: false,
      vaInfo: {},
      accountInfo: {
        receiveCurrency: i18n.global.t('pages_receive_006'),
        receiveAccountName: i18n.global.t('pages_receive_004'),
        receiveAccountNumber: i18n.global.t('pages_receive_122'),
        bicCode: 'SWIFT/BIC Code:',
        bankName: i18n.global.t('pages_receive_123'),
        bankAddress: i18n.global.t('pages_receive_124'),
        realBankCode: i18n.global.t('pages_receive_125'),
        branchCode: i18n.global.t('pages_receive_126'),
        areaCodeChinese: i18n.global.t('pages_receive_007'),
        accountType: i18n.global.t('pages_receive_127'),
        createTime: i18n.global.t('pages_receive_128')
      },
      shopList: [],
      infoArr: [],
      capacityArr: [],
      nameForm: { id: '', simpleName: '' },
      copyFields: ['receiveAccountName', 'receiveAccountNumber', 'bicCode', 'bankName', 'bankAddress', 'realBankCode', 'branchCode'],
      columns: [
        { key: 'shopName', dataIndex: 'shopName', title: i18n.global.t('pages_receive_021'), align: 'center' },
        { key: 'platText', dataIndex: 'platText', title: i18n.global.t('pages_receive_136'), align: 'center' },
        { key: 'statusText', dataIndex: 'statusText', title: i18n.global.t('pages_receive_137'), align: 'center' },
        { key: 'authText', dataIndex: 'authText', title: i18n.global.t('pages_receive_138'), align: 'center' }
      ]
    })

    const nameUpdate = () => {
      formRef.value.validateFields()
        .then(values => {
          return _receive.updateSimpleName(state.nameForm)
        })
        .then(res => {
          if (res.data.succ) {
            state.visible = false
            message.success(i18n.global.t('common_text_022'))
            state.vaInfo.simpleName = state.nameForm.simpleName
          }
        })
        .catch(err => console.log(err))
    }

    const menuChange = async (item) => {
      if (item.key === 'normal') {
        state.exportLoading = true
        await _receive.downOpenCert({ id: state.vaInfo.id, businessType: 'normal' })
        state.exportLoading = false
      } else if (item.key === 'amazon') {
        state.openVisible = true
        state.openText = 'Amazon'
      } else {
        state.openVisible = true
        state.openText = 'Ebay'
      }
    }

    const loadAccountDetail = async (bankCode, bankAccount) => {
      const infoArr = []
      const res = await _receive.getAccountDetail({ bankCode, bankAccount })
      if (res.data.succ) {
        const { data } = res.data
        const { accountInfo, copyFields } = state
        state.accountInfo['bicCode'] = data.bankAddressTitle
        for (const i in accountInfo) {
          if (accountInfo[i]) {
            if (['realBankCode', 'branchCode', 'accountType'].includes(i) && !data[i]) continue
            infoArr.push({
              key: i,
              name: accountInfo[i],
              value: i === 'createTime' ? moment(data[i] * 1000).format('YYYY-MM-DD HH:mm:ss') : data[i],
              copy: copyFields.includes(i) && data[i]
            })
          }
        }
        if (data.remark1) {
          const remarks = JSON.parse(data.remark1)
          const capacityArr = []
          for (const i of remarks) {
            for (const k in i) {
              capacityArr.push({
                value: k === 'name' ? getTradeStatusName(i[k], 'vaTime') || '-' : i[k]
              })
            }
          }
          state.capacityArr = capacityArr
        }
        state.infoArr = infoArr
      }
    }

    const loadShopDetail = async (shopBankAccount) => {
      const res = await _receive.getShopDetail({ shopBankAccount })
      if (res.data.succ) {
        const { data } = res.data
        if (data) {
          state.shopList = [
            Object.assign({}, data, {
              authText: getTradeStatusName(data.auth, 'authStatus') || '-',
              statusText: getTradeStatusName(data.status, 'shopApproveStatus') || '-',
              platText: getTradeStatusName(data.platCode, 'receiveBusinessChildType') || '-'
            })
          ]
        }
      }
      state.loading = false
    }

    const loadAccountInfo = async (id) => {
      state.loading = true
      const res = await _receive.geVaDetail({ id })
      if (res.data.succ) {
        const { data } = res.data
        state.vaInfo = res.data.data
        state.nameForm.simpleName = state.vaInfo.simpleName
        state.nameForm.id = state.vaInfo.id
        loadAccountDetail(data.bankCode, data.tradeBankAccount)
        if (!['20', '30'].includes(data.businessType)) {
          state.allowAuth = true
          loadShopDetail(data.tradeBankAccount)
        }
      }
    }

    loadAccountInfo(route.query.id)
    return {
      formRef,
      nameUpdate,
      menuChange,
      getCountryLogo,
      copyToClipboard,
      getTradeStatusName,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#tradeDetail {
  .common-card-body {
    padding: 18px 36px;
    text-align: left;
    .back-tab {
      cursor: pointer;
      .ic-back {
        color: @main-color;
        font-size: 18px;
        margin-right: 4px;
      }
    }
    .account-top {
      margin-top: 24px;
      .top-area {
        cursor: pointer;
        .name {
          font-size: 16px;
          font-weight: 500;
          margin-left: 12px;
        }
        .ic-edit {
          font-size: 18px;
          color: @main-color;
          margin: 0 0 5px 5px;
        }
        img {
          width: 36px;
        }
      }
      .account-export {
        margin-left: auto;
      }
    }
    .account-body {
      display: flex;
      margin-top: 36px;
      .body-left, .body-right {
        flex: 1;
        overflow: hidden;
        .title {
          font-size: 16px;
          font-weight: 500;
        }
        .content {
          display: flex;
          overflow: hidden;
          margin-top: 18px;
          .content-left {
            flex: 1;
            background: @table-th-color;
            padding: 0 15px;
            text-align: center;
            border-radius: 8px 0 0 8px;
            .name {
              height: 50px;
              line-height: 50px;
              white-space: nowrap
            }
          }
          .content-right {
            flex: 3;
            border: 1px solid #EFEFEF;
            border-radius: 0 8px 8px 0;
            overflow: hidden;
            .name {
              height: 50px;
              line-height: 50px;
              padding: 0 24px;
              border-bottom: 1px solid #EFEFEF;
              .area {
                flex: 20;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .ic-copy {
                color: @main-color;
                margin-left: auto;
                cursor: pointer;
                flex: 1;
              }
            }
            .name:last-child {
              border: 0;
            }
          }
        }
      }
      .body-right {
        margin-left: 48px;
      }
    }
    .account-footer {
      margin-top: 36px;
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 24px;
      }
    }
  }
}
</style>
